<template>
  <div>
    <v-row>
      <h1 class="ml-4">To Do lists</h1>
    </v-row>

    <v-card class="mt-3">
      <v-card-title>
        <v-text-field
          v-model="search"
          placeholder="Type an todolists name to search..."
          outlined
          clearable
          counter
        ></v-text-field>
      </v-card-title>
      <v-card class="ma-4">
        <v-list>
          <v-list-item
            v-for="(item, index) in todolist.records"
            :key="index"
            v-bind:class="{ 'task-completed': item.done }"
            two-line
          >
            <v-checkbox
              hide-details
              v-model="item.done"
              class="mt-0 mr-2"
            ></v-checkbox>
            <v-list-item-content>
              <v-list-item-title class="red--text">{{
                item.description
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.createdAt }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card-text>
        <!-- <v-data-table
          :headers="headers"
          :items="todolists.records"
          :loading="isLoading"
          :search="search"
          transition="scale-transition"
          class="new-detault-table todolists-view"
        >
          <template v-slot:top>
            <v-row class="card-slider mb-4" justify="center">
              <v-col cols="12" sm="4" md="4" lg="4">
                <download-excel
                  class="text-right"
                  :data="todolists.records"
                  :fields="fields"
                  worksheet="Todolists"
                  name="CRMTodolistList.xls"
                >
                  <v-btn class="text-right" color="#0a2752" large dark>
                    <h1>Download</h1>
                  </v-btn>
                </download-excel>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-btn
                  dark
                  color="primary"
                  large
                  @click="$router.push('/user/todolist/0/edit')"
                  ><h1>Create new Todolist</h1></v-btn
                >
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <h3 color="primary">{{ item.createdAt | getFormattedDate }}</h3>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <h3 color="primary">{{ item.updatedAt | getFormattedDate }}</h3>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              fab
              x-small
              dark
              class="mr-2"
              @click="editItem(item)"
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              dark
              class="ml-2"
              @click="deleteItem(item)"
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
// import defaultProfile from "../../../assets/img/avatar.png";
// import TotalTodolistsStatisticsCard from "../../../components/Base/StatsCards/TotalTodolistsStatisticCard.vue";
export default {
  components: {
    // TotalTodolistsStatisticsCard,
    //  defaultProfile
  },
  data() {
    return {
      fields: {
        "Todolist Name": "name",
        "Todolist Id": "id",
        "Created At": "createdAt",
      },
      headers: [
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "Created Date", value: "createdAt" },
        { text: "Updated Date", value: "updatedAt" },
        { text: "Actions", align: "right", sortable: false, value: "actions" },
      ],
      search: "",
      todolists: [],
      totalRecords: 0,
      isLoading: false,
    };
  },

  apollo: {
    todolists: {
      query: gql`
        query Todolists($limit: Int!, $skip: Int!, $query: JSON!) {
          todolists(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              description
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    editItem(item) {
      this.$router.push(`/user/todolist/${item.id}/edit`);
    },

    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteTodolist(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "Todolist was successfully deleted",
                "success"
              );
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {}
        }
      });
    },
  },
};
</script>

<style scoped>
.task-completed {
  background-color: #d8d8d8;
  opacity: 0.6;
}
</style>
